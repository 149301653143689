<template>
  <section id="dashboard-ecommerce">
    
    <div v-if="$can('read','interview')">

        <b-card>
          <div>
            <h5>Select Fields</h5>
             <b-row>
              <b-col md="1" xl="1" xs="1" class="mt-3">
                <span variant="outline-secondary" type="submit"  @click="clearSelected" >Clear</span>
              </b-col>
              <b-col md="11" xl="11" xs="1">
                <b-form-group class="demo-inline-spacing">
                  <b-form-checkbox-group
                    id="checkbox-group-1"
                    v-model="selected"
                    :options="interviewOptions"
                    name="fields-1"
                    class="demo-inline-spacing"
                  />
                </b-form-group>  
              </b-col> 
            </b-row> 
            <b-row>
              <b-col col v-if="$can('read','manager_filter')">
                <b-form-group
                  label="Team Lead"
                  label-for="accountMgr"
                >
                  <v-select
                      v-model="mgrSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="accountMgr"
                      :reduce="(option) => option.emp_id"
                      ref="accMgr"
                    />
                </b-form-group>
              </b-col>
              <b-col col v-if="$can('read','recruiter_filter')">
                <b-form-group 
                  label="Recruiter"
                  label-for="recruiter"
                >
                  <v-select
                      v-model="recSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="recruiters"
                      :reduce="(option) => option.emp_id"
                      ref="rec"
                    />
                </b-form-group>
              </b-col>
              <b-col col v-if="$can('read','recruiter_filter')">
                <b-form-group
                  label="Location"
                  label-for="location"
                >
                  <v-select
                      v-model="locSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="location"
                      :reduce="(option) => option.title"
                      ref="loc"
                    />
                </b-form-group>
              </b-col>
              <b-col col>
                <b-form-group
                  label="Client"
                  label-for="client"
                >
                  <v-select
                      v-model="clientSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="clients"
                      :reduce="(option) => option.title"
                      ref="client"
                    />
                </b-form-group>
              </b-col>
              <b-col col>
                <b-form-group
                  label="Source"
                  label-for="source"
                >
                  <v-select
                      v-model="sourceSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="sources"
                      :reduce="(option) => option.title"
                      ref="source"
                    />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col md="4" xl="4" xs="4" class="mb-1">
                <b-form-group
                  label="Start Date"
                  label-for="start-date"
                >
                  <flat-pickr
                    v-model="startDate"
                    class="form-control"
                    :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
                  />
                  <small v-if="!startDate" class="text-danger">Start Date is Mandatory</small>
                </b-form-group>
              </b-col>    
              <b-col md="4" xl="4" xs="4" class="mb-1">
                <b-form-group
                  label="End Date"
                  label-for="end-date"
                >
                  <flat-pickr
                    v-model="endDate"
                    class="form-control"
                    :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
                  />
                  <small v-if="!endDate" class="text-danger">End Date is Mandatory</small>
                </b-form-group>
              </b-col>
              <b-col md="4" xl="4" xs="4" class="mb-1">
                <b-form-group
                  label=""
                  label-for="">
                  <b-button variant="outline-primary" value="submit" id="submit" type="submit"  @click="getReport" style="margin-top: 20px">Get Report</b-button>
                  <span class="mb-2 ml-1" @click="clearFilter">Clear Filter</span>
                </b-form-group>
              </b-col>
            </b-row>
            
            <div v-if="loading">
                <div class="text-center">
                  <b-spinner variant="primary" label="Text Centered" />
                </div>
            </div>
            <div v-if="error" class="text-danger">{{ error }}</div>
            <div v-if="!loading && compiledData">
              
              <download-excel :data="compiledData" :name="filename" v-if="isReadyToDownload && $can('read','recruiter_filter')" style="max-width: 170px;" >
                <button type="button" class="btn btn-success mb-1">Download Report</button>
              </download-excel>
            <!-- <b-table striped hover responsive :items="compiledData"></b-table> -->
            <b-row>
      
            <b-col
              md="4"
              sm="4"
              lg="4"
              class="my-1"
              style="font-weight: 430;"
            > Total Candidates : {{ totalRows }}
          </b-col>
            <b-col
              md="8"
              sm="8"
              lg="8"
              class="my-1"
            >
              <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                    trim
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            </b-row>
            <b-table
              striped
              hover
              responsive
              :items="compiledData"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #cell(interview_id)="data" >
                    <div class="text-nowrap text-success" @click="showInterviewTimeline(data.item)" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-modal.modal-interview-timeline>
                        {{ data.item.interview_id }} 
                    </div>
                
              </template>
              <template #cell(candidate_name)="data">
                <router-link
                              :to="{
                                name: 'apps-candidates-view',
                                params: { id: data.item.cid, jobId: data.item.jid },
                                query: { position_name: data.item.position_name,
                                        client: data.item.client,
                                        attachments: data.item.attachments,
                                        recruiter: data.item.recruiter,
                                        locations: data.item.locations,
                                        sourcer: data.item.sourcer,
                                        am_name: data.item.account_mgr,
                                        position_type: data.item.type,
                                        vertical: data.item.vertical,
                                        category: data.item.category,
                                        payroll: data.item.payroll
                                }
                              }"
                            >
                    <div class="text-nowrap" style="font-weight: 430;  width: 150px !important; text-overflow:ellipsis; overflow:hidden ;white-space: initial">
                        {{ data.item.candidate_name }}<br/>
                        {{ data.item.candidate_id }}
                    </div>
                </router-link>
              </template>  
              <template #cell(position_name)="data">
              <router-link :to="'/apps/view-job/' + data.item.jid">
              <div class="text-nowrap" style="font-weight: 430;  width: 150px !important; text-overflow:ellipsis; overflow:hidden ;white-space: initial">
                  {{ data.item.position_name }} <br/>
                  {{ data.item.job_id }} <br/>
                  {{ data.item.client }} 
                  </div>
              </router-link>
              </template>
             
              <template #cell(recruiter)="data">
                  <div class="text-nowrap">
                    {{ data.item.recruiter }} (R)<br/>
                    {{ data.item.team_lead }} (TL)<br/>
                  </div>
              </template>
              <!-- <template #cell(recruiter)="data">
                  <div class="text-nowrap">
                    {{ data.item.scheduled_by }} (A)<br/>
                    {{ data.item.recruiter }} (R)<br/>
                    {{ data.item.sourcer }} (S)<br/>
                    {{ data.item.account_mgr }} (AM)<br/>
                    {{ data.item.team_lead }} (TL)<br/>
                  </div>
              </template> -->
              <template #cell(candidateStatus)="data">
                  <div class="text-nowrap">
                  {{ data.item.candidateStatus }} <br/>
                  {{ data.item.source }} 
                  </div>
              </template>
              <template #cell(status)="data">
                  <div class="text-nowrap">
                    <b-badge pill class="text-capitalize"
                      :class="resolveInterviewStatusVariant(data.item.status)">
                        {{ data.item.status }}
                    </b-badge>
                  </div>
              </template>
              <template #cell(date_time)="data">
                  <div class="text-nowrap">
                    {{ interview_date_localFormat(data.item.date_time) }}
                  </div>
              </template>
              <template #cell(actions)="data">
                <div v-if="(data.item.status === 'Scheduled' || data.item.status === 'Rescheduled By Client' || data.item.status === 'Rescheduled By Candidate') && $can('update','interview')">
                  <div  class="text-nowrap text-success pb-1" @click="sendCandidateItemToModal(data.item, 'A-NA')" 
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-modal.modal-interview-update>
                    <b-badge pill class="badge-info">
                      Update
                    </b-badge>
                  </div>
                  <div  class="text-nowrap text-success pb-1" @click="sendCandidateItemToModal(data.item, 'R')" v-if="$can('update','interview')" 
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-modal.modal-interview-update>
                    <b-badge pill class="badge-warning">
                      Reschedule
                    </b-badge>
                  </div>
                  <div class="text-nowrap text-success" @click="sendCandidateItemToModal(data.item, 'Cancelled')" v-if="$can('update','interview')"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-modal.modal-interview-update>
                    <b-badge pill class="badge-secondary">
                      Cancel
                    </b-badge>  
                  </div>
                </div>  
              </template>
              
            </b-table>
            </div>
            
            <div v-if="!loading && !compiledData ">
              <span class="text-danger">{{ reportStatus }}</span>
            </div>   
          </div>
        </b-card>  
      </div>
      <!--:title="candidate_id + ' - ' + candidate_name + ' - ' + position + ' - ' + client"-->
      <b-modal
        id="modal-interview-update"
        ok-title="submit"
        cancel-variant="outline-secondary"
        @ok="updateInterview"
      >
        <b-form >
          <b-form-group v-if="interviewStatusSel === 'R'"
              label="Is Reschedule Time Available ?"
              label-for="interview-date-checkbox"
              id="resched-time-avail"
            >
          
            <b-form-checkbox
                v-model="checked"
                class="custom-control-primary"
                name="check-button"
                switch
              />
        </b-form-group>   
            <b-form-group v-if="interviewStatusSel === 'R' && checked"
              label="Enter Rescheduled Date Time *"
              label-for="interview-date"
              id="resched-time"
            >
              <flat-pickr
                  class="form-control"
                   
                  v-model="interview_date" 
                  :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
              />
              
            </b-form-group>
            
            <!-- <small v-if="!interview_date && error" class="text-danger">{{ error }}</small> -->
            <b-form-group label="Select Interview Status *" label-for="interview-status" v-if="interviewStatusSel === 'A-NA'">
              <v-select
                  v-model="interviewStatusSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="interviewStatusOptions"
                  label="title"
                  :reduce="(option) => option.title"
              />
            </b-form-group>
            <b-form-group label="Select Interview Reschedule Status *" label-for="interview-recshedule-status" v-if="interviewStatusSel === 'R'">
              <v-select
                  v-model="interviewStatusSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="interviewRescheduleOptions"
                  label="title"
                  :reduce="(option) => option.title"
              />
            </b-form-group>
            <b-form-group
              label="Enter notes"
              label-for="interview-notes"
            >
              <b-form-textarea
                id="textarea-default"
                placeholder="Enter notes/comment"
                v-model="interview_notes"
                rows="3"
              />
            </b-form-group>
          </b-form>
      </b-modal>
      <b-modal
        id="modal-interview-timeline"
        ok-only
        ok-title="OK"
        centered
        size="xl"
      >
        <b-table responsive="sm" :items="interviewTimeline" :fields="timelineFields"> 
          <template #cell(interview_id)="data" >
              <div class="text-nowrap">
                {{ data.item.interview_id }}
              </div>
          </template>
          <template #cell(scheduled_by)="data" >
              <div class="text-nowrap">
                {{ data.item.scheduled_by }}
              </div>
          </template>
          <template #cell(status)="data" >
              <div class="text-nowrap">
                <b-badge pill class="text-capitalize"
                      :class="resolveInterviewStatusVariant(data.item.status)">
                        {{ data.item.status }}
                </b-badge>
              </div>
          </template>
          <template #cell(interview_datetime)="data" >
              <div class="text-nowrap">
                {{ interview_date_localFormat(data.item.interview_datetime) }}
              </div>
          </template>
          <template #cell(comment)="data" >
              <div class="text-nowrap">
                {{ data.item.comment }}
              </div>
          </template>
          <template #cell(added_on)="data" >
              <div class="text-nowrap">
                {{ data.item.added_on }}
              </div>
          </template>
        </b-table>
      </b-modal>  
  </section>
</template>

<script>
import { BRow, BCol, BCard, BButton, BForm, BFormGroup, BSpinner, BFormCheckbox, BFormCheckboxGroup, BTable, BBadge, BModal, VBModal, BFormTextarea, BFormInput, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import moment from 'moment'
import UserService from '@/services/user-service'
import clientService from '@/services/client-service'
import interviewService from '@/services/interview-service'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import adminConfigService from "@/services/admin-config-service";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm, 
    BFormGroup,
    BSpinner,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormInput, BInputGroup, BInputGroupAppend,
    // ExportExcelSheetVueJs,
    ToastificationContent,
    BFormTextarea,
    BTable,
    BBadge,
    vSelect, BModal, VBModal, flatPickr,
    
  },
  data() {
    return {
      startDate: '',
      endDate: '',
      interview_date: '',
      tracker: '',
      compiledData: [],
      loading: false,
      downloadReport: '',
      recruiters: '',
      recSelected: '',
      accountMgr: '',
      mgrSelected: '',
      sourceSelected: '',
      clients: '',
      filter: null,
      sortDirection: 'asc',
      filterOn: [],
      clientSelected: '',
      location: [{ title: 'Pune' }, { title: 'Delhi'}],
      locSelected: '',
      filename: '',
      columns: [{ label: 'Manager', field: 'Manager'}, { label: 'Recruiter', field: 'Recruiter'}, { label: 'Client', field: 'Client'}, { label: 'Location', field: 'Location'}, { label: 'Total Candidates', field: 'TotalCandidates'}, 
        { label: 'Profile Shared', field: 'ProfileShared'}, { label: 'Review Reject', field: 'ReviewReject'}, { label: 'Initiated', field: 'Initiated'}, { label: 'Not Interested', field: 'NotInterested'}, { label: 'Not Relevant', field: 'NotRelevant'}, 
        { label: 'Not Answered', field: 'NotAnswered'}, { label: 'Not Reachable', field: 'NotReachable'}, { label: 'Wrong Number', field: 'WrongNumber'}, { label: 'Call Back', field: 'CallBack'}, { label: 'Screen Select', field: 'ScreenSelect'},
        { label: 'Screen Reject', field: 'ScreenReject'}, { label: 'Duplicate', field: 'Duplicate'}, { label: 'InProcess', field: 'InProcess'}, { label: 'On Hold', field: 'OnHold'}, { label: 'Shortlisted', field: 'Shortlisted'},
        { label: 'Rejected', field: 'Rejected'}, { label: 'Offered', field: 'Offered'}, { label: ' Not Offered', field: 'NotOffered'}, { label: 'Offer Drop', field: 'OfferDrop'}, { label: 'Joined', field: 'Joined'}],
      reportStatus: '',
      
      // options: [
      //   { text: 'Recruiter', value: 'recruiter' },
      //   { text: 'Team Lead', value: 'manager' },
      //   { text: 'Location', value: 'location' },
      //   { text: 'Client', value: 'client' },
      //   { text: 'Source', value: 'source' },
      // ],
      //interviewStatusOptions: [{ title: "Naukri" }, { title: "Monster" }],
      sources: [
        { title: "Naukri" },
        { title: "Monster" },
        { title: "ATS" },
        { title: "LinkedIn" },
        { title: "Indeed" },
        { title: "Shine" },
        { title: "TimesJobs" },
        { title: "IIMJobs" },
        { title: "Glassdoor" },
        { title: "FreshersWorld" },
        { title: "Other" },
        { title: "Train N Hire" },
        { title: "Internal" },
        { title: "Referenced" },
      ],
      sheetname: 'Status Wise Tracker',
      isReadyToDownload: false,
      sourceSelected: '',
      fields: [
        { key: 'interview_id', label: 'ID', sortable: true, thStyle: { width: "5%" }	 },
        { key: 'candidate_name', label: 'Name', sortable: true, thStyle: { width: "15%" } },
        { key: 'position_name', label: 'Position', sortable: true, thStyle: { width: "15%" } },
        { key: 'recruiter', label: 'Recruiter', sortable: true, thStyle: { width: "10%" } },
        { key: 'candidateStatus', label: 'Candidate Status', sortable: true, thStyle: { width: "10%" } },
        { key: 'status', label: 'Interview Status', sortable: true, thStyle: { width: "10%" } },
        { key: 'date_time', label: 'Interview Date', sortable: true, thStyle: { width: "15%" } },
        { key: "actions", label: 'Actions', thStyle: { width: "20%" } },
        
      ],
      timelineFields: [
      { key: 'interview_id', label: 'ID', sortable: true },
        { key: 'scheduled_by', label: 'SCHEDULED BY', sortable: true },
        { key: 'status', label: 'Interview Status', sortable: true },
        { key: 'interview_datetime', label: 'Interview Date', sortable: true },
        { key: "comment", label: 'comment' },
        { key: "added_on", label: 'Added On' },
      ],
      selected: ["Scheduled","Attended","Not Attended","Rescheduled By Client","Rescheduled By Candidate","Cancelled"],
      interviewOptions: [
        { text: "Scheduled", value: "Scheduled" },
        { text: "Attended", value: "Attended"},
        { text: "Not Attended", value: "Not Attended"},
        { text: "Rescheduled By Client", value: "Rescheduled By Client"},
        { text: "Rescheduled By Candidate", value: "Rescheduled By Candidate"},
        { text: "Cancelled", value: "Cancelled" }
      ],
      interviewStatusOptions: [
        { title: "Attended"},
        { title: "Not Attended"},
      ],
      interviewRescheduleOptions: [
        { title: "Rescheduled By Client"},
        { title: "Rescheduled By Candidate"},
      ],
      interview_notes: '',
      interviewTimeline: [],
      error: '',
      sortBy: '',
      sortDesc: '',
      interviewItems: [],
      interviewStatusSelected: '',
      interviewStatusSel: '',
      checked: true
    }
  },
  directives: {
      'b-modal': VBModal,
      Ripple,
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    
  },
  created() {
    if(this.$route.query.startDate || this.$route.query.rec_id) {
      this.recSelected = this.$route.query.rec_id 
      this.startDate = this.$route.query.startDate
      this.endDate = this.$route.query.endDate
      this.filter = this.$route.query.name
    } else {
    this.startDate = moment().locale("en").add(-7, 'days').format("YYYY-MM-DD")
    this.endDate = moment().locale("en").add(7, 'days').format("YYYY-MM-DD")
    }
    const emp_id = JSON.parse(localStorage.getItem('userData')).emp_id

    const dt = new Date('2023-03-07T14:50:00.000Z')
    console.log('moment testing',moment(dt).utcOffset(0).format("DD MMM YYYY HH:mm"))
    // UserService.getAllUsersDd().then( res => {
    //   if(res.status === "OK") {
    //     console.log("in if")
    //     this.recruiters = res.data.filter(item => item.role === "talent-advisor-sourcer" || item.role === "account-manager" || item.role === "talent-advisor");
    //     this.accountMgr = res.data.filter(item => item.role === "account-manager");
    //   }
      
    // })
    UserService.getAllTeamMembersList(emp_id).then(res =>{
      console.log('recruiters ',res.data)
      this.recruiters = res.data
      // for (let i = 0; i < this.recruiters.length; i++) {
      //       const id = "'" + this.recruiters[i].emp_id + "'|"
      //       // console.log(id)
      //       this.all_ids = this.all_ids + id
      // }
      this.getReport()
    })
    UserService.getAllManagersList().then(res =>{
      console.log('managers ',res.data)
      this.accountMgr = res.data
      // for (let i = 0; i < this.recruiters.length; i++) {
      //       const id = "'" + this.recruiters[i].emp_id + "'|"
      //       // console.log(id)
      //       this.all_ids = this.all_ids + id
      // }
      
    })

    clientService.getClients().then(res => {
      this.clients = res.data
      console.log(this.clients)
    })
    
    
  },
  methods: {
    getReport() {
      if(!this.$can('read','interview')) {
        return
      } 
      this.error = ''
      this.compiledData = []
      this.tracker = []
      const emp_id  = JSON.parse(localStorage.getItem('userData')).emp_id
      const userRole = JSON.parse(localStorage.getItem('userData')).roles[0]

      if (this.startDate === '' || this.endDate === '') {
        this.reportStatus = 'Please Select Dates'
      } else {
        this.loading = true
        console.log(this.startDate, this.endDate, moment().locale("en").format("YYYY-MM-DD"))
        //console.log('DATE CHECK', new Date(this.startDate) < new Date(moment().locale("en").format("YYYY-MM-DD")))
        // this.rec = this.selected.includes('recruiter')
        // this.mgr = this.selected.includes('manager')
        // this.loc = this.selected.includes('location')
        // this.client = this.selected.includes('client')
        console.log(this.selected)
        this.recSelected = this.recSelected == null || this.recSelected == undefined ? '' : this.recSelected
        this.mgrSelected = this.mgrSelected == null || this.mgrSelected == undefined ? '' : this.mgrSelected
        this.locSelected = this.locSelected == null || this.locSelected == undefined ? '' : this.locSelected
        this.clientSelected = this.clientSelected == null || this.clientSelected == undefined ? '' : this.clientSelected
        this.clientSelected = this.sourceSelected == null || this.sourceSelected == undefined ? '' : this.sourceSelected
        // if(this.recSelected) {
        //   this.$router.addRoutes({ query: {scheduled_by: this.recSelected} });
        // }
        // if(this.mgrSelected) {
          
        // }
        // if(this.locSelected) {
          
        // }
        // if(this.clientSelected) {
          
        // }
        // if(this.clientSelected) {
          
        // }
        if (new Date(this.startDate) <= new Date(moment().locale("en").format("YYYY-MM-DD")) && (new Date(this.startDate) <= new Date(this.endDate))) {
          interviewService.getAll(this.startDate, this.endDate, this.recSelected, this.mgrSelected, this.clientSelected, this.locSelected , this.sourceSelected, emp_id, userRole).then(res => {
            if (res.status === "OK") {
              //this.reportStatus = ''
              if (this.selected.length === 6 ) {
                this.compiledData = res.data
              } else if(this.selected.length > 0) {
                for (let i = 0; i < this.selected.length; i++) {
                  const data = res.data.filter( item => item.status === this.selected[i])
                  this.compiledData = [...this.compiledData, ...data]
                }
              } else {
                  this.compiledData = res.data
              }
              //this.route.query
              // if(this.compiledData.length === res.data.length) {
              //   this.compiledData = res.data
              // }
              
              this.isReadyToDownload = true
              console.log(res)
              //this.downloadReport = 'data:application/xlsx;base64,' + res.file
              this.filename = 'Interview Tracker - ' + this.startDate + '_' + this.endDate + '.xls'
              
              this.loading = false

              if(this.compiledData.length === 0) {
                this.error = 'No Data found for selected dates & filter'
              }
            } else if ((res.status === 401 ) || (res.status === 403 )) {
              window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
              this.reportStatus = 'Session Expired... Redirecting to Login Page'
              localStorage.clear();
              this.$router.push({ name: "auth-login" });
            } else if ((res.status === 400 ) ) {
              this.reportStatus = 'No Data Found for Selected Dates'
              this.loading = false
              this.compiledData = null
            }
            else if ((res.status === 500 ) ) {
              this.reportStatus = 'ERROR: ' + res.data.message + 'Kindly contact IT admin.'
              this.loading = false
              this.compiledData = null
            }
          })
          
        } else if (new Date(this.startDate) > new Date(this.endDate)) {
          this.loading = false
          this.reportStatus = 'Start Date cannot be greater than End Date!!!'
        }
          else {
          this.loading = false
          this.reportStatus = 'Start Date cannot be greater than today!!!'
        }
      }  
    },
    updateInterview() {
      this.error = ''
      //if(this.interview_date) {

        // if(this.interviewStatusSelected === 'Attended' || this.interviewStatusSelected === 'Not Attended') {
        //   this.interview_date
        // }

        const userData = JSON.parse(localStorage.getItem("userData"));
        // const user = `[{"title" : "${userData.first_name} ${userData.last_name}", "emp_id": "${userData.emp_id}"}]`;
        // const username = `${userData.first_name} ${userData.last_name}`
        console.log('interviewStatusSelected', this.interviewStatusSelected)
        const data = {
          candidate_id: this.cid,
          job_id: this.jobid,
          scheduled_by: userData.emp_id,
          date_time: this.checked === false ? null : this.interview_date,
          status: this.interviewStatusSelected,
          parent_id: this.interview_id,
          comment: this.interview_notes
        }
        console.log(data)
        interviewService.create(data).then(res => {
          if (res.status === "OK") {
            //this.reportStatus = ''
            console.log(res)
            //this.downloadReport = 'data:application/xlsx;base64,' + res.file
            this.$toast({
                          component: ToastificationContent,
                          props: {
                          title: "Interview "+ this.interviewStatusSelected +" !!!",
                          icon: "EditIcon",
                          variant: "success",
                          },
                      });
            this.getReport()                        
            
          } else if ((res.status === 401 ) || (res.status === 403 )) {
            window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
            this.error = 'Session Expired... Redirecting to Login Page'
            localStorage.clear();
            this.$router.push({ name: "auth-login" });
          } else if ((res.status === 400 ) ) {
            this.error = 'Some Error Occurred, Please Try after sometime. If Same Issue occurs multiple times, Kindly contact IT admin.'
            this.loading = false
            this.compiledData = null
          }
          else if ((res.status === 500 ) ) {
            this.$toast({
                          component: ToastificationContent,
                          props: {
                          title: "Interview "+ this.interviewStatusSelected +" Failed!!!",
                          icon: "EditIcon",
                          variant: "danger",
                          },
                      });
            this.error = 'ERROR: ' + res.data.message + 'Kindly contact IT admin.'
            this.loading = false
            this.compiledData = null
          }
        })
        // } else {
        // this.error = 'Interview Date is Mandatory'
        // }
    },
    showInterviewTimeline(data) {
      this.error = ''
      console.log('cid-jid', data.cid, data.jid, data.parent_id)
      const iid = data.parent_id ? data.parent_id : data.interview_id
      interviewService.getCandidateJobEvent(data.cid, data.jid, iid).then(res => {
          if (res.status === "OK") {
            console.log(res)
            this.interviewTimeline = res.data
            
          } else if ((res.status === 401 ) || (res.status === 403 )) {
            window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
            this.error = 'Session Expired... Redirecting to Login Page'
            localStorage.clear();
            this.$router.push({ name: "auth-login" });
          } else if ((res.status === 400 ) ) {
            this.error = 'Some Error Occurred, Please Try after sometime. If Same Issue occurs multiple times, Kindly contact IT admin.'
            this.loading = false
            this.compiledData = null
          }
          else if ((res.status === 500 ) ) {
            this.$toast({
                          component: ToastificationContent,
                          props: {
                          title: "Interview details get Failed!!!",
                          icon: "EditIcon",
                          variant: "danger",
                          },
                      });
            this.error = 'ERROR: ' + res.data.message + 'Kindly contact IT admin.'
            this.loading = false
            this.compiledData = null
          }
        })
    },
    sendCandidateItemToModal(data,status) {

      if(status === "A-NA") {
        this.interviewStatusSel = 'A-NA'
        this.interviewStatusSelected = 'Attended'
      } else if (status === 'R') {
        this.interviewStatusSel = 'R'
        this.interviewStatusSelected = 'Rescheduled By Client'
      }else {
        this.interviewStatusSel = 'Cancelled' 
        this.interviewStatusSelected = status
      }
      console.log('status', this.interviewStatusSelected)
      
      this.cid = data.cid
      this.jobid = data.jid
      this.cname = data.candidate_name
      this.position = data.position_name
      this.client = data.client
      this.currentStatus = data.candidateStatus
      this.interview_date = data.date_time
      this.interview_status = data.status
      this.interview_notes = data.comment
      this.interview_id = data.parent_id ? data.parent_id : data.interview_id
      this.revenue = data.revenue
      this.revenue_confirmed = data.revenue_confirmed
      },
    numDaysBetween(d1, d2) {
        var diff = Math.abs(d1.getTime() - d2.getTime());
        return diff / (1000 * 60 * 60 * 24);
    } ,
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      //this.currentPage = 1
    }, 
    resolveInterviewStatusVariant(status) {
    //console.log("called")
    //const resolveCandidateStatusVariant = status => {
      
      if (status === 'Scheduled') return 'badge-info'
      if (status === 'Rescheduled By Client') return 'badge-warning'
      if (status === 'Rescheduled By Candidate') return 'badge-danger'
      if (status === 'Cancelled') return 'badge-danger'
      if (status === 'Not Attended') return 'badge-danger'
      if (status === 'Attended') return 'badge-success'
      //return 'badge-secondary'
    },
    interview_date_localFormat(dt) {
      // create a new date object with a specific datetime
      if(dt) {
        const dt1 = new Date(dt)
        console.log('moment testing',moment(dt1).utcOffset(0).format("DD MMM YYYY HH:mm"))
        return moment(dt1).utcOffset(0).format("DD MMM YYYY HH:mm")
      } else {
        return ''
      }
    },
    clearSelected() {
      this.selected = []
    },
    onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
    },
    clearFilter() {
      this.recSelected = ''
      this.mgrSelected = ''
      this.locSelected = ''
      this.clientSelected = ''
      this.sourceSelected = ''
      if(this.$route.query) {
        //this.$router.push('/apps/my-candidates')
        this.filter = null
        this.$router.replace({ query: {} });
      }
    } 
  },
  mounted() {
    adminConfigService.getATSDropdownList().then(res => {
      if(res.status === 200) {
        this.sources = res.data.data.sourceList
        // this.qualifications = res.data.data.qualificationList
      }
    })
  },
  // updated: function () {
  //   this.$nextTick(function () {
  //     // Code that will run only after the
  //     // entire view has been re-rendered
      
  //     const scrollPosition = localStorage.getItem('lastPosition')
  //     console.log('called',JSON.stringify(scrollPosition))
  //     console.log('last position', JSON.parse(scrollPosition).y  )
  //     window.scrollTo(0, JSON.parse(scrollPosition).y);
  //   })
  // },
  updated: function () {
      console.log('called in updated')
      this.$nextTick(function () {
        console.log('called in updated nextTick')
        // Code that will run only after the
        // entire view has been re-rendered
        
        const scrollPosition = localStorage.getItem('lastPosition')
        const y = JSON.parse(scrollPosition).y
        console.log('called in updated nextTick getting localstorage', typeof scrollPosition, scrollPosition, y)

          setTimeout( () => {
            console.log('called in updated nextTick after settimeout', scrollPosition, y)
            if(y) {
              window.scrollTo(0, y);
            }
          },500);
      })
  },
  
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style scoped>
table, td {
  border: 1px solid rgb(97, 97, 97);
  border-collapse: collapse;
}
.col-my10 {
  width: 10% !important;
}
.col-my14 {
  width: 14% !important;
}
.col-my16 {
  width: 16% !important;
}
.col-my6 {
  width: 6% !important;
}
.col-my84 {
  width: 84% !important;
}
.col-my17 {
  width: 16.6% !important;
}
.col-my20 {
  width: 19.1% !important;
}
.col-my7 {
  width: 7.1447% !important;
}
.col-my40 {
  width: 40% !important;
}
.text-v-h-center {
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
}
td {
  word-wrap: break-word !important;
  max-width: 15% !important;
}
</style>
